import React from 'react'

// App components
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App content
import GrantAndWife from '../../assets/grant-and-wife.jpg'
import LoveOrtho from '../../assets/love-ortho.jpg'
import WithMug from '../../assets/with-mug.jpg'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, metrics, presets } from '../../utils/theme'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title="Cost of Braces and Invisalign"
            bannerKey={'ORTHODONTICS'}
          />
          <div css={themeStyles.textPadding}>
            <img
              src={LoveOrtho}
              css={{
                [presets.Tablet]: {
                  float: 'left',
                  marginRight: metrics.defaultMargin,
                },
                display: 'block',
                maxHeight: '500px',
                float: 'center',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
                marginTop: rhythm(1),
              }}
            />
            <h1 css={{ ...themeStyles.blueText, marginTop: rhythm(1) }}>
              How much do braces cost and why should I invest in braces?
            </h1>
            <p>
              So you have determined that you or your child needs braces, but
              now to ask the question that everyone wants to know – how much are
              the braces going to cost? Some people don’t care, whereas others
              are dreading it. I get it. In fact, I’ve been there. Medical
              bills. Insurance bills. Taxes. Car payments. House payments.
              Health Insurance. Life is expensive, and it can cause a lot of
              stress. But I have great news for you. Braces can be more
              affordable than you think. Before we even get into how affordable
              braces can really be, understand this – braces are an investment.
              An investment in yourself or your child.
            </p>
            <p>
              There are many places and ways you can invest and spend money.
              Some people invest in a new car. Others invest money in a boat.
              You can spend money on vacations, homes, bills, sports, hobbies,
              clothing, and in countless other places. So why should braces take
              priority over these items? The difference between braces and all
              of that “other stuff” is this – your investment is in yourself or
              your child. More specifically, your child’s smile, bite, and
              overall oral health. Although the braces only last an average
              period of 1-2 years, the effects of the braces will last a
              lifetime.
            </p>
            <p>
              Having straight teeth will not only affect your smile, but also
              countless studies in psychology have shown that improving a
              person’s smile will improve overall self-confidence, ability to
              get jobs, career advancement, and social status. I’m not saying
              that it’s fair, or that it’s right. It’s just how it is in our
              society, and the studies support this. Time and time again, I’ve
              seen our patients’ personalities transform simply because they
              feel confident with their new smiles. This investment not only
              improves a patient’s present smile, but also it pays dividends in
              confidence for your child over the course of a lifetime.
            </p>
            <p>
              I’m passionate about changing patients’ lives by improving their
              smiles – otherwise I wouldn’t be an orthodontist! I truly believe
              that every kid should get the opportunity to have a great smile,
              and the great news is this – your child can have a great smile
              without you feeling stressed about the cost of treatment. Your
              overall investment for braces will vary, depending on several
              factors, including the difficulty of the case, the extent of the
              treatment desired, whether it’s early treatment versus later
              comprehensive treatment, geographic location, whether you choose
              invisalign, braces or clear braces, and many other factors.
            </p>
            <p>
              In the state of Minnesota, a standard comprehensive orthodontic
              treatment fee ranges between $3000 for very limited cases to
              $10,000 or more for extensive cases – most standard orthodontic
              cases fall somewhere in the middle of this range. However, the
              good news is this – if you can find the right orthodontist, you
              will have the opportunity for a financing plan that fits your
              budget. Not all orthodontic offices will do this. It’s just a
              matter of you finding the right office. Some people come into our
              office simply wanting to know about the full payment discount,
              whereas others want to find a monthly amount that feels
              comfortable with their budgets. Find an orthodontic office that
              adapts to fit your budget and individual needs.
            </p>
            <p>
              At our office, we find ways to make it work and that means being
              flexible with the patient and family needs. We understand that
              every person that walks through our doors comes from a different
              background, has different budgets, different goals, has a
              different number of kids that may need braces, and will require a
              different financial plan to work with these personal situations.
              Our goal is to find ways to make things work. I tell all of our
              patients and parents, “We never want to trade a dental problem for
              a financial problem” and for that reason, we are extremely
              flexible with our financing options in order to find ways to make
              it work for you to invest in your child’s smile and future. When
              you are looking for an orthodontic office, always ask about their
              financial plans before signing up for treatment.
            </p>
            <img
              src={WithMug}
              css={{
                [presets.Tablet]: {
                  float: 'right',
                  marginLeft: metrics.defaultMargin,
                },
                display: 'block',
                maxWidth: '400px',
                float: 'center',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
                marginTop: rhythm(2),
              }}
            />
            <h1 css={themeStyles.blueText}>
              What should I be discussing with the orthodontist?
            </h1>
            <p>
              Payment plans, flex spending, insurance and financing… all of
              these items can be intimidating and confusing, but there are
              certain areas that you need to understand well in order to discuss
              with your orthodontist.
            </p>
            <h3>Payment Plans</h3>
            <p>
              Make sure to find an orthodontist that offers some type of
              flexible payment plan that allows you to make 0% interest monthly
              payments. It is important that your orthodontist is flexible in
              working with you to make sure you’re comfortable with the amount
              you pay each month. Some offices will have strict policies
              regarding finances and payment, often times saying things such as
              "You owe us 50% the day you get your braces on, and then you have
              12 to 18 months to pay off the remaining balance."
            </p>
            <p>
              At our office, we choose to do things differently. I tell my
              Financial Coordinator to be compassionate and understanding
              because people will have different budgets and different needs. So
              you can choose. Do you want a large full payment discount, or
              would you prefer to keep your monthly payments low over a long
              period of time? We don’t have a sign in our office that says this
              is our policy. Instead, we have a motto, and here it is: we never
              want to trade dental problems for financial problems. That’s it.
              That is why we offer the most flexible payment plans around.
            </p>
            <h3>Flex Spending Accounts</h3>
            <p>
              Flex Spending Accounts (FSA) are a great way to help families
              afford orthodontic treatment. These accounts are set up through
              your place of employment, and they allow you to use pre-tax
              dollars to set aside for medical expense, ultimately saving you
              money. Make sure to ask your place of employment about Flex
              Spending Accounts, because it can definitely help when paying for
              orthodontic treatment.
            </p>
            <h3>Insurance</h3>
            <p>
              Check with the orthodontic office to see if they will do a
              complimentary insurance benefits check for you. Many types of
              insurance plans will have an orthodontic benefit; it’s just a
              matter of discovering what the benefit includes, its limits (age,
              lifetime maximum), and its disbursement schedule – all of these
              factors will be important to understand as well. Orthodontic
              benefits, though part of your dental insurance, are usually
              structured differently than benefits that cover dental cleanings,
              fillings, etc.
            </p>
            <p>
              Orthodontic insurance generally has a lifetime maximum (an amount
              pre-determined by the plan that will be the maximum amount an
              insurance company will pay); stricter limits (age limits are very
              common); and disbursement schedules (some will disburse benefits
              in two to three chunk payments – the day the braces are placed, at
              6 months, at 12 months – and some will pay monthly).
            </p>
            <p>
              Checking your benefit can be complicated, so if you are struggling
              to figure out your insurance benefit, or even if it’s difficult to
              interpret exactly what your insurance benefit states, be sure to
              bring your insurance card to your orthodontic appointment and let
              the office experts do the hard work for you.
            </p>
            <h3>Financing</h3>
            <p>
              Third party financing for treatment is also an option. Whether
              through family and friends or through a loan obtained via a
              lending institution, you may be able to qualify for low or
              no-interest loans that allow you to obtain the finances you need
              for orthodontic treatment.
            </p>
            <img
              src={GrantAndWife}
              css={{
                [presets.Tablet]: {
                  float: 'right',
                  marginLeft: metrics.defaultMargin,
                },
                display: 'block',
                maxWidth: '420px',
                float: 'center',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
                marginTop: rhythm(2),
              }}
            />
            <h1 css={themeStyles.blueText}>
              What other factors will increase the cost of braces?
            </h1>
            <p>
              There are many factors that may affect the cost of braces. The
              first is this – when calculating the actual cost of orthodontic
              treatment, be sure to factor in the time and income lost from
              missing work or school. One of the most common complaints of
              parents is the overall time it takes for orthodontic appointments.
              Think about it.
            </p>
            <p>
              Parents must leave work, pick up their child from school, drive
              the child to the orthodontist, find parking, wait for the
              appointment to complete, drive the child back to school and then
              drive back to work. The total amount of time you have to be away
              from work can be several hours, which is lost income for you, and
              not to mention valuable time that your child will be missing
              school. In order to minimize your time away from work, make sure
              to choose an orthodontist that offers the following:
            </p>
            <ul>
              <li>Early morning, lunchtime, and evening appointments.</li>
              <li>Easy and quick parking.</li>
              <li>
                Easily accessible locations, i.e. near highways and main roads
                that allow quick travel.
              </li>
              <li>
                “Same day starts.” If your child is ready for treatment, a “same
                day start” can save you a lot of time, and usually a lot of
                money too. It basically allows you to have the new patient exam,
                records (i.e. x-rays, photos, etc.), dental molds, and the
                consultation with the doctor all in the same day.
              </li>
            </ul>
            <p>
              Make sure your orthodontist respects your time and does not draw
              out multiple appointments prior to even getting braces. Lost time
              from missing work or school is time that you can never get back.
              Some orthodontic offices schedule a whopping four to five separate
              one-hour appointments prior to braces going on, which could mean
              waiting several months before getting started! Just imagine how
              much school your child will miss before the braces even go on.
              That being said, find an orthodontist that values your time.
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
